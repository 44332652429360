.container__menu {
  display: flex;
  flex-direction: row;
  position: fixed;
  width: 300px;
  height: fit-content;
  z-index: 1200;
  right: -300px;
  top: 20%;
  align-items: center;
  transition: all ease 0.7s;
  user-select: none;
  justify-content: center;
}
.container__arrow {
  position: absolute;
  justify-content: center;
  left: -52px;
  display: flex;
  background-color: #C9961A;
  align-items: center;
  padding: 15px 0 15px 15px;
  border-radius: 15px;
  height: 57px;
  transition: all ease 0.4s;
}
.container__arrow:hover {
  background-color: #C9961A;
  cursor: pointer;
}

.open {
  transition: all ease 0.4s;
  right: 0 !important;
}
.open .container__arrow {
  padding: 15px 15px 15px 0;
}
.open .container__arrow svg {
  transform: rotate(180deg);
}

.container__arrow svg {
  font-size: 250%;
  color: white;
  transition: all ease 0.7s;
}
.container__lista {
  background-color: #C9961A;
  width: 100%;
  height: 100%;
  border-radius: 30px 0 0 30px;
  overflow: hidden;
}
.container__lista > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 393px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container__lista > ul::-webkit-scrollbar:vertical {
  width: 15px;
  padding-right: 10px;
}
.container__lista > ul::-webkit-scrollbar-track {
  background: transparent;
}
.container__lista > ul::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 5px solid ·C9961A;
}
.container__lista > ul::-webkit-scrollbar-thumb:hover {
  background: rgb(216, 216, 216);
}

.container__lista > ul > a {
  text-decoration: none;
  color: black;
}
.container__lista > ul > a > li {
  display: flex;
  text-align: center;
  align-items: center;
  border-top: 1px solid white;
  padding: 10px;
  transition: all ease 0.4s;
}

.container__lista > ul > a > li:hover {
  background-color: #C9961A;
  color: rgb(199, 199, 199);
  cursor: pointer !important;
}
.container__lista > ul > a > li svg {
  font-size: 250%;
}
.container__lista > ul > a > li > span {
  display: block;
  font-weight: bold;
  width: 100%;
  font-size: 100%;
  overflow-x: hidden;
}

@media (max-width: 767px) and (min-width: 501px) {
  .container__arrow svg {
    font-size: 200%;
  }
  .container__lista > ul > a > li svg {
    font-size: 200%;
  }
  .container__lista > ul > a > li span {
    font-size: 70%;
  }
}
@media (max-width: 500px) {
  .container__arrow svg {
    font-size: 200%;
  }
  .container__lista > ul > a > li svg {
    font-size: 200%;
  }
  .container__lista > ul > a > li span {
    font-size: 70%;
  }
}
/* .container__menu{
    height: 80vh;
    right: 10px;
    top: 150px;
    position: fixed;
    z-index: 1020;
    padding: 5px;
}
.container__lista{
    flex-direction: column;
    height: 40px;
    padding: 0;
    margin: 0;
    transition: all ease 0.5s;
    overflow-y: hidden;
    
}


.open{
    transition: all ease 1s !important;
    height: 100%;
}

.container__lista li{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    cursor: pointer;
    transition: all ease 0.4s;
    margin-bottom: 10px;
}
.container__lista li:hover{
    background: rgb(223, 223, 223);
}
ul,li{
    list-style: none;
} */
