.container_alertas {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 10px;
}
.ant-drawer-header{
  border-top-left-radius: 18px !important;
}
.ant-drawer-content{
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  background-color: transparent;
}
.container_alerta_contingencia{
  display: flex !important;
  min-width: 40% !important;
  padding-left: 15px !important;
}
.MuiLinearProgress-barColorPrimary{
  background-color: #0FAB0B !important;
}
.MuiLinearProgress-root {
  height: 10px !important;
  overflow: hidden !important;
  position: relative !important;
  border-radius: 5px !important;
}
.boton_enviar_contingencia{
  background-color: #0FAB0B !important;
  width: 130px !important;
  color:white !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
  margin-left: 15px !important;
  height: 30px;
  
}
.boton_enviar_contingencia_disabled{
  background-color: #C7C8CD !important;
  width: 130px !important;
  color:#888A97 !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
  margin-left: 15px !important;
  height: 30px;
}
.alerta {
  padding: 10px;
  width: 90%;
  min-width: 265px;
  box-shadow: var(--shadow) !important;
  margin-right: 10px;
}
.cont_alert {
  width: 100%;
  display: flex;
  justify-content: center;
}
.MuiAlert-message {
  width: 100% !important;
  text-align: center;
}
.hoverAlertas {
  cursor: pointer;
}
.alerta button {
  padding: 4px 10px;
}

.MuiAlert-standardError .MuiAlert-icon {
  color: #876fc1 !important;
}

.cont_filtro {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 52px;
  border-radius: 10px;
  box-shadow: var(--shadow);
  cursor: pointer;
  background-color: #fff;
  position: relative;
}
.cont_filtro1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 52px;
  border:none;
  cursor: pointer;
  background-color: transparent;
  position: relative;
  justify-self: right;
}
.cont_filtro_logo {
  width: 100%;
  display: flex;
  justify-content: center;
}
.cont_filtro_logo img {
  width: 40px;
  opacity: 0.7;
  transition: all .4s linear;
}

.cont_filtro_logo img:hover {
  opacity: 0.9;
}
.filter_container {
  position: absolute;
  bottom: -140px;
  z-index: 3;
  animation: traslateIn .5s cubic-bezier(0.075, 0.82, 0.165, 1);
  left: -220px;
}
@keyframes traslateIn {
  0% {
    left: 100px;
    opacity: 0;
  }
  100% {
    left: -220px;
    opacity: 1;
  }
}
@keyframes traslateInPhone {
  0% {
    left: 100px;
    opacity: 0;
  }
  100% {
    left: 40px;
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .alerta {
    margin-bottom: 10px;
  }
  .container_alertas {
    margin-top: 10px;
    margin-bottom: 30px;
    justify-content: space-between;
    flex-direction: column;
    gap:10px;
  }
  .cont_filtro {
    width: 90%;
    margin-right: 10px;
  }
  .cont_filtro1 {
    width: 90%;
    margin-right: 10px;
  }
  .filter_container {
    animation: traslateInPhone .5s cubic-bezier(0.075, 0.82, 0.165, 1);
    left: 40px;
  }
}

.drawerAlert{
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 10px;
}