.name-regional {
    font-size: 1rem;
    font-weight: 600;
}

.cardRegional {
    display: flex !important;
    width: 100%;
    flex-direction: row !important;
    justify-content: space-between !important;
}

.regional-btn {
    cursor: pointer;
    transition: transform 0.2s ease;
}

.regional-btn:hover {
  transform: scale(1.1);
  color:red !important /* Escala el elemento al 105% del tamaño original al hacer hover */
}
.card-regional{
    margin-top: 1rem;
}