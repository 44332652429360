.card_indicadores {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 222px;
    background-color: #000;
    padding: 10px;
    gap: 5px;
}

.card_indicadores-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #FFF;
    width: 30%;
}

.card_indicadores-header img {
    width: 35px;
    margin-bottom: 5px;
}

.card_indicadores-ventasFidelizadas img {
    height: auto;
    border-radius: 0;
}

.card_indicadores-header h4 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px !important;
    margin: 0px;
    font-weight: 600;
    text-align: left;
}

.card_indicadores-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 3px 10px;
}

.card_indicadores-body span,
.card_indicadores-body p {
    margin: 0;
}

.card_indicadores-body span {
    font-size: 15px !important;
    font-weight: 500;
}

.card_indicadores-body p {
    font-weight: bold;
    font-size: 20px;
}

.card_indicadores-footer {
    width: 100%;
    height: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card_indicadores-footer p,
.card_indicadores-footer span {
    margin: 0;
    color: #FFF;
}

.card_indicadores-footer p {
    font-size: 14px !important;
    text-transform: uppercase;
    font-weight: 500;
}

.card_indicadores-footer span {
    font-weight: bold;
    font-size: 18px;
}


.container_items {
    height: 80px;
    padding: 0;
    overflow-x: scroll;
    display: flex;
    align-items: center;
    gap: 5px;
    max-width: 60%;
}

.card_indicadores_items {
    min-width: 110px;
    height: 100%;
    padding: 7px 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    text-transform: uppercase;
    text-align: center;

    &.odd {
        border: 2px solid var(--secondary-color);

        & h4,
        & p,
        & span {
            color: #fff !important;
        }
    }

    &.even {
        border: 2px solid #FFF;

        & h4,
        & p,
        & span {
            color: var(--secondary-color) !important;
        }
    }
}

.card_indicadores_items h4 {
    font-weight: bold;
    color: #FFF;
    font-size: 12px !important;
    margin: 0;
}

.card_indicadores_items span,
.card_indicadores_items p {
    color: #FFF;
    margin: 0;
}

.card_indicadores_items p {
    font-weight: bold;
    font-size: 15px;
}

.container_items::-webkit-scrollbar {
    width: 3px;
}

.container_items::-webkit-scrollbar:vertical {
    width: 3px;
}

.container_items::-webkit-scrollbar-track {
    opacity: 0 !important;
}

.container_items::-webkit-scrollbar-thumb {
    border-radius: 20px;
}

.container_items::-webkit-scrollbar-thumb:hover {
    background: rgb(216, 216, 216);
}