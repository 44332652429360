

.title-conciliacion-red {
    font-size: 1.2rem !important;
    font-weight: bold !important;
    color:green;
    font-family: terpelPosFont;
}
.title-conciliacion-green {
    font-size: 1.2rem !important;
    font-weight: bold !important;
    color:#DE1B08 !important;
    font-family: terpelPosFont;

}