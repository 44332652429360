.container__tanque {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    position: relative;
    flex-direction: column;
    width: 290px;
    height: 130px;
    border-radius: 100px;
    border: 1px solid rgba(128, 128, 128, 0.411);
    background: linear-gradient(180deg, rgba(244, 244, 244, 0.712) 0%, rgb(251, 251, 251) 25%, rgb(231, 230, 230) 70%, rgba(164, 164, 164, 0.676) 100%);
}

.container__cilindro {
    --aumento: 10%;
    height: 80%;
    width: 45%;
    position: absolute;
    top: 13px;
    left: 78px;
    overflow: hidden;
    background: rgba(216, 216, 216, 0.452);
    border-radius: 20px;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.5);
    border: 2px solid rgba(128, 128, 128, 0.411);
}

.liquido {
    transform: scale(0);
    position: absolute;
    /* border-radius: 100px/30px; */
    bottom: -16px;
    width: 100%;
    transition: all 1s linear;
    cursor: pointer;
}


/* COMPONENTES DEL TANQUE */

.medida__tanque {
    width: 15px;
    position: absolute;
    top: 33px;
    left: 60px;
}

.base__tanque {
    width: 268px;
    position: absolute;
    bottom: -27px;
    left: 10px;
}

.escalera__tanque {
    height: 90%;
    position: absolute;
    top: 0;
    right: 45px;
}

.cabecera__tanque {
    width: 120px;
    position: absolute;
    top: -27px;
    left: 82px;
}


/* FIN COMPONENTES DEL TANQUE */

.activo {
    transform: scale(1);
    background-color: red;
    height: 50px;
}

.container__label {
    color: black;
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
    position: absolute;
    top: 20px;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
}

.container__info {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.container__info h2 {
    text-align: center;
    font-size: 1.2rem !important;
    font-weight: bold;
    color: black;
    background-color: rgba(128, 128, 128, 0.096);
    border-radius: 15px;
}

.container__info p {
    font-size: 0.8rem !important;
    margin: 0;
    color: black;
    margin-bottom: 5px;
}

.container__info p span {
    font-size: 0.9rem !important;
    color: red;
    text-align: center;
    float: right;
    margin-left: 50px;
}

.icono {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 3%;
    right: 68px;
    z-index: 500;
}

.icono svg {
    width: 100%;
    height: 100%;
    color: rgb(255, 255, 255);
    border-radius: 50%;
    background-color: red;
}

.container__tipo {
    display: flex;
    position: absolute;
    width: 100%;
    bottom: -65px;
}

.container__tipo h2 {
    width: 100%;
    font-size: 1rem !important;
    font-weight: bold;
    text-align: center;
}

.container__nombre {
    display: flex;
    position: absolute;
    width: 100%;
    top: -45px;
}

.container__nombre h2 {
    width: 100%;
    height: 40px;
    font-size: 0.7rem !important;
    font-weight: bold;
    text-align: center;
}


/* Anchos */

.xs {
    /* 100x200 */
    min-width: 120px !important;
    width: 120px !important;
    height: 240px;
    --aumento: 9.3%;
}

.xs .container__cilindro {
    width: 90%;
}

.xs .container__cilindro .liquido:after {
    height: 25px;
    top: -2px;
}

.xs .container__cilindro:before {
    height: 35px;
}

.xs .container__cilindro:after {
    height: 35px;
}

.xs .container__cilindro .liquido .container__label {
    font-size: 0.7rem;
}

.sm {
    /* 250x400 */
    width: 250px;
    height: 400px;
    --aumento: 9.76%;
}

.sm .container__cilindro {
    /* 250x400 */
    width: 70% !important;
}

.sm .container__cilindro:after {
    height: 60px;
}

.sm .icono {
    width: 50px;
    height: 50px;
    right: 35px;
}

.sm .container__cilindro .liquido .container__label {
    font-size: 1rem;
    margin-top: -50px;
}

.md {
    /* 350x500 */
    width: 350px;
    height: 500px;
    --aumento: 9.8%;
}

.md .icono {
    width: 50px;
    height: 50px;
    right: 50px;
}

.md .container__cilindro .liquido .container__label {
    font-size: 1rem;
}

.lg {
    /* 450x600 */
    width: 450px;
    height: 600px;
    --aumento: 9.75%;
}

.lg .icono {
    width: 60px;
    height: 60px;
    right: 50px;
}

.lg .container__cilindro .liquido .container__label {
    font-size: 1rem;
}

.lg .container__cilindro {
    width: 60%;
}

.lg .container__cilindro:before {
    height: 65px;
}

.lg .container__cilindro:after {
    height: 65px;
}

.xl {
    /* 550x700 */
    width: 550px;
    height: 700px;
    --aumento: 9.85%;
}

.xl .icono {
    width: 80px;
    height: 80px;
    right: 20px;
}

.xl .container__cilindro {
    width: 60%;
    border-radius: 110px/30px;
}

.xl .container__cilindro .liquido .container__label {
    margin-top: -50px;
    font-size: 1.2rem;
}

.xl .container__cilindro:before {
    width: 99%;
    height: 60px;
}

.xl .container__cilindro:after {
    width: 99%;
    height: 60px;
}

.md .container__cilindro,
.sm .container__cilindro {
    width: 60%;
}


/* Porcentajes */

.p0 {
    transform: scale(0);
}

.p1 {
    height: calc(var(--aumento) + 16px);
}

.p2 {
    height: calc(var(--aumento) * 2 + 16px);
}

.p3 {
    height: calc(var(--aumento) * 3 + 16px);
}

.p4 {
    height: calc(var(--aumento) * 4 + 16px);
}

.p5 {
    height: calc(var(--aumento) * 5 + 16px);
}

.p6 {
    height: calc(var(--aumento) * 6 + 16px);
}

.p7 {
    height: calc(var(--aumento) * 7 + 16px);
}

.p8 {
    height: calc(var(--aumento) * 8 + 16px);
}

.p9 {
    height: calc(var(--aumento) * 9 + 16px);
}

.p10 {
    height: calc(var(--aumento) * 10 + 16px);
}

.p1 .container__label,
.p2 .container__label,
.p3 .container__label,
.p4 .container__label,
.p5 .container__label {
    margin-top: calc(var(--aumento) * -4.4);
    color: black !important;
}

.p10 .container__label,
.p9 .container__label,
.p8 .container__label,
.p7 .container__label,
.p6 .container__label {
    margin-top: calc(var(--aumento) * 4);
}


/* COLORES */

.black {
    background: black;
    box-shadow: 0 0 6px #8d8d8da6;
}

.black::after {
    background-color: rgb(29, 29, 29);
    box-shadow: 0 0 4px #8d8d8da6;
}

.white {
    background: white;
    box-shadow: 0 0 6px #c9c9c993;
}

.white::after {
    background-color: rgb(235, 235, 235, 0.650);
    box-shadow: 0 0 6px #c9c9c993;
}

.red {
    background: rgba(255, 34, 0, 0.586);
    box-shadow: inset 0 0 6px rgb(154, 21, 1);
}

.blue {
    background: rgba(55, 0, 255, 0.538);
    box-shadow: inset 0 0 6px rgb(29, 0, 136);
}

.cyan {
    background: rgba(0, 255, 255, 0.700);
}

.yellow {
    background: rgba(251, 255, 0, 0.715);
    box-shadow: inset 0 0 6px #ab8600;
}

.gray {
    background: rgba(128, 128, 128, 0.700);
}

.orange {
    background: rgba(255, 166, 0, 0.700);
}

.green {
    background: lime;
}

.gray .container__label,
.black .container__label,
.blue .container__label,
.red .container__label {
    color: white;
}
