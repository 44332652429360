.container-item-menu-dock {}

.dock-item-menu {
    display: flex;
    justify-content: space-between;
    outline: 1px #C5C5C5 solid;
    border-radius: 17px;
    height: 3rem;
    width: 100%;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
}

.dock-item-menu:hover {
    outline: 1px var(--secondary-color) solid;

}

.item-dock-menu-button {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    border-inline: none;
}
.title-dock-item{
    font-size: 0.8rem !important;
}