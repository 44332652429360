
.container__box{
    background-color: white;
    box-shadow: var(--shadow);
    border-radius: 10px;
    position: relative;
    margin: .691%;
    transition: all ease 0.8s;
    padding: 40px 10px;
}
@media (max-width: 768px) {
    .container__box {
        height:  600px;
        width: 100%!important;
    }
}
.container__contenido{
    height: 100%;
    width: 100%;
}

.box_hover:hover{
    transition: all ease 0.2s;
    box-shadow: var(--shadow-hover);
    transform: scale(1.01);
}

.minimize{
    transition: all ease 0.2s;
}

.menu{
    justify-content: space-between;
    top: 5px;
    padding: 5px 15PX;
    display: flex;
    width: 100%;
    position: absolute;
    border-radius: 10px;
    flex-direction: row;
}

.handle{
    border-radius: 10px;
    cursor: grab !important;
}
.selected{
    transform: scale(1.03) !important;
    background-color: rgb(230, 230, 230);
}
.selected .menu .opciones .handle{
    cursor: grabbing !important;
}
.handle svg{
    background-size: contain;
    transform: rotate(90deg);
}
.drag{
    opacity: 0 !important;
}
.opciones{
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 5px;
    right: 5px;
}

.opcion{
    cursor: pointer;
    transition: all ease 0.3s;
    padding: 5px;
    border-radius: 50%;
}
.opcion:hover{
    background-color: rgba(187, 187, 187, 0.596);
    transform: scale(1.02);
}
.opcion:active{
    transition: all ease 0.2s;
    background-color: rgba(139, 139, 139, 0.705);
}

.expanded{
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    height: 85vh;
    width: 80vw;
    overflow: hidden;
    z-index: 1101;
    display: flex;
    margin: auto ;
    transition: all ease 1s;
}

.expanded .container__contenido{
    transform: scale(1.1);
    width: 70%;
    height:70%;
    margin: auto;
}

.titulo{
    width: 80%;
    display: flex;
    align-items: center;
    overflow-X: auto;
}
.titulo::-webkit-scrollbar:horizontal{
    height: 3px;
}

.titulo h2{
    margin: 0;
    font-size: 120% !important;
    font-weight: bold;
}


/* @keyframes expandir{
    from{
        transform: translateX(0);
        transform: translateY(0);
    }
    to{
        position: absolute;
        transform: translateX(25%);
        transform: translateY(25%);
    }
} */

@media  (min-width:1200px){
    .box-lg-12{width: 100%;}
    .box-lg-11{width: 91.66666666666666%;}
    .box-lg-10{width: 83.33333333333333%;}
    .box-lg-9{width: 75%;}
    .box-lg-8{width: 65%;}
    .box-lg-7{width: 58.33333333333333%;}
    .box-lg-6{width: 50%;}
    .box-lg-5{width: 41.66666666666667%;}
    .box-lg-4{width: 31.9%;}
    .box-lg-3{width: 25%;}
    .box-lg-2{width: 16.66666666666667%;}
    .box-lg-1{width: 8.333333333333333%;}
}
@media (max-width:1199px) and (min-width:992px){
    .box-md-12{width: 100%;}
    .box-md-11{width: 91.66666666666666%;}
    .box-md-10{width: 83.33333333333333%;}
    .box-md-9{width: 75%;}
    .box-md-8{width: 65%;}
    .box-md-7{width: 58.33333333333333%;}
    .box-md-6{width: 50%;}
    .box-md-5{width: 41.66666666666667%;}
    .box-md-4{width: 31.9%;}
    .box-md-3{width: 25%;}
    .box-md-2{width: 16.66666666666667%;}
    .box-md-1{width: 8.333333333333333%;}
}
@media (max-width:991px) and (min-width:768px){
    .box-sm-12{width: 100%;}
    .box-sm-11{width: 91.66666666666666%;}
    .box-sm-10{width: 83.33333333333333%;}
    .box-sm-9{width: 75%;}
    .box-sm-8{width: 65%;}
    .box-sm-7{width: 58.33333333333333%;}
    .box-sm-6{width: 50%;}
    .box-sm-5{width: 41.66666666666667%;}
    .box-sm-4{width: 31.9%;}
    .box-sm-3{width: 25%;}
    .box-sm-2{width: 16.66666666666667%;}
    .box-sm-1{width: 8.333333333333333%;}

    #expandir{
        display: none;
    }
    .expanded{
        overflow-y: scroll;
    }
    .expanded::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    .expanded::-webkit-scrollbar-button:increment, .expanded::-webkit-scrollbar-button{
        display: none !important;
    }
    .expanded::-webkit-scrollbar:vertical{
        width: 8px;
       
    }
    .expanded::-webkit-scrollbar-button{
        background: red !important;
    }
    .expanded::-webkit-scrollbar-thumb {
        background-color: #797979;
        border-radius: 30px;
        border: 2px solid #f1f2f3;
    }
    .expanded::-webkit-scrollbar-track {
        width: 100px; 
    }
    
    
}
@media  (max-width:767px){
    .box-xs-12{width: 100%;}
    .box-xs-11{width: 91.66666666666666%;}
    .box-xs-10{width: 83.33333333333333%;}
    .box-xs-9{width: 75%;}
    .box-xs-8{width: 65%;}
    .box-xs-7{width: 58.33333333333333%;}
    .box-xs-6{width: 50%;}
    .box-xs-5{width: 41.66666666666667%;}
    .box-xs-4{width: 31.9%;}
    .box-xs-3{width: 25%;}
    .box-xs-2{width: 16.66666666666667%;}
    .box-xs-1{width: 8.333333333333333%;}
    
    .expanded{
        overflow-y: auto;
        height: fit-content;
        max-height: 98vh;
        margin: auto !important ;
    }

    #expandir{
        display: none;
    }

    .container__box{
        margin: 10px auto;
    }
}