.react-calendar {
    width: 300px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}
.react-calendar--doubleView {
    width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}
.react-calendar__navigation button {
    min-width: 44px;
    background: #C9961A;
    color: #ffffff;
}
.react-calendar__navigation button:disabled {
    background-color: #C9961A;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
    color: #C9961A;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
    color: black;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}
.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
}
.react-calendar__tile:disabled {
    background-color: #f0f0f0;
    width: 50%; /* Ajusta el ancho según tus necesidades */
    height: 50%;
    font-weight: bold;
    border-radius: 50%;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #f6dede;
    width: 50%; /* Ajusta el ancho según tus necesidades */
    height: 50%;
    font-weight: bold;
    border-radius: 50%;
}
.react-calendar__tile--now {
    background: #f0f0f0;
    color: #5d5d5a;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #f0f0f0;
}
.react-calendar__tile--hasActive {
    background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
    width: 50%; /* Ajusta el ancho según tus necesidades */
    height: 50%;
    font-weight: bold;
    border-radius: 50%;
}
.react-calendar__tile--active {
    background: white;
    color: #5d5d5a;
    width: 50%; /* Ajusta el ancho según tus necesidades */
    height: 50%;
    font-weight: bold;
    border-radius: 50%;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #f1b1b1;
    width: 50%; /* Ajusta el ancho según tus necesidades */
    height: 50%;
    font-weight: bold;
    border-radius: 50%;
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

.noConsolidado {
    background-color: white;
    color: red;
    width: 50%; /* Ajusta el ancho según tus necesidades */
    height: 50%;
    font-weight: bold;
    border-radius: 50%;
    border:1px solid !important;
    padding: -30% !important;
    box-sizing: border-box !important;
}
.cosolidado {
    color: #1bb528;
    background-color: white;
    color: #1bb528;
    width: 50%; /* Ajusta el ancho según tus necesidades */
    height: 50%;
    font-weight: bold;
    border-radius: 50%;
    border:1px solid !important;
    padding: -30% !important;
    box-sizing: border-box !important;
}

.buttonPicker {
    color: gray !important;
    height: 50px;
    background: 'white';
    border-color: #ffffff;
    border-bottom: 1px solid gray;
}

.buttonPicker:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 0px;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    transition: all 1s ease;
}

.buttonPicker:hover:before {
    width: 100%;
}

.btn-grad {
    display: flex;
    height: 40px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: gray;
    box-shadow: 0 0 20px #eee;
    border-color: #ffffff;
    border-bottom: 1px solid gray;
    border-radius: 8px !important;
}

.btn-grad:hover {
    background-image: linear-gradient(to right, white 0%, #C9961A 50%, #C9961A 100%);
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}
