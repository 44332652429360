.legend {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex-direction: column;
    justify-content: center;
  }
  
  .circle {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
   border: 1px solid;
  }
  
  .description {
    font-size: 0.8rem;
    text-align: center;
  }
  