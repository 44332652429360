/*Grids List Styles*/

.gl-image,
.gl-list,
.gl-advanced,
.gl-single-line {
  @include display-flex();
  @include justify-content(space-around);
  overflow: hidden;
}

.gl {
  max-width: 100%;
  max-height: 360px;
  // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
  transform: translateZ(0);
}

.gl-single-line {
  & .slg {
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    max-width: 100%;
  }
}

.title-gradient {
  background-image: -webkit-linear-gradient(bottom, rgba($black, 0.7) , rgba($black, 0.3) , rgba($black, 0)) !important;
  background-image: -o-linear-gradient(bottom, rgba($black, 0.7), rgba($black, 0.3), rgba($black, 0)) !important;
  background-image: linear-gradient(to bottom, rgba($black, 0.7), rgba($black, 0.3), rgba($black, 0)) !important;
}

.title-gradient-bottom {
  background-image: -webkit-linear-gradient(top, rgba($black, 0.7) , rgba($black, 0.3) , rgba($black, 0)) !important;
  background-image: -o-linear-gradient(top, rgba($black, 0.7), rgba($black, 0.3), rgba($black, 0)) !important;
  background-image: linear-gradient(to top, rgba($black, 0.7), rgba($black, 0.3), rgba($black, 0)) !important;
}
