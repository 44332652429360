.login-container {
    height: 100vh;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-background {
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: contain !important;
}

.login-content {
    position: relative;
    width: 408px;
    height: 560px;
    padding: 1rem;
    border: #C9961A 1px solid;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.53) 40%, #C9961A 250%);
    border-radius: 22px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
}

.logo-wrapper {
    text-align: center;
    margin-bottom: 1rem;
}

.logo {
    margin-top: 15px;
    width: 130px;
    height: 70px;
}

.login-title {
    display: block;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    color: #fff;
    margin-bottom: 1.8rem;
    margin-top: 3.4rem;
}

.input-icon-user {
    color: rgba(255, 255, 255, 0.7);
    width: 1rem;
}

.input-icon-password {
    color: rgba(255, 255, 255, 0.7);
    width: 1.3rem;
}

.user-input,
.password-input {
    background-color: transparent;
    /* Fondo transparente */
    border: 1.2px solid #fff;
    border-radius: 12px;
    height: 34px;
    padding-left: 0px;
}

#usernameLogin.ant-input::placeholder {
    color: #C5C5C5 !important;
}

#passwordLogin.ant-input::placeholder {
    color: #C5C5C5 !important;
}

:where(.user-input, .password-input).ant-input-affix-wrapper>input.ant-input {
    background-color: transparent !important;
    text-align: center;
    color: #fff !important;
}

.ant-form-item {
    margin-bottom: 12px;
}

.input-prefix {
    width: 125px;
    height: 34px;
    background-color: black;
    border: 1.2px solid white;
    border-radius: 12px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.prefix-text {
    color: white;
    font-size: 14px;
}

.login-button {
    width: 100%;
    background-color: #C9961A;
    border-color: #C9961A;
    color: #000;
    height: 36px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 14px;
}

.forgot-password {
    display: block;
    text-align: center;
    color: #C9961A;
    font-size: 0.9rem;
}

.forgot-password a {
    position: absolute;
    top: 25px;
    transform: translateX(-50%);
    color: #C9961A;
    text-align: center;
    color: #fff !important;
    text-decoration: underline 1px #fff !important;
}

.version-text {
    position: absolute;
    right: 20px;
    bottom: 10px;
    text-align: end;
    color: #fff !important;
    font-size: 0.9rem;
}

@media (max-width: 1490px) {
    .login-background {
        height: 100vh;
        background-size: cover !important;
    }
}

@media (max-width: 768px) {
    .input-prefix {
        opacity: 0;
        visibility: hidden;
    }
    .logo {
        margin-top: 20px;
    }
    .login-title {
        margin-top: 2rem;
        margin-bottom: 4rem;
        font-size: 1.4rem;
    }
    .login-button {
        margin-top: 18px;
        font-size: 1rem;
    }
    .forgot-password a {
        font-size: 1rem;
    }
    .version-text {
        font-size: 1rem;
    }
    .ant-form-item-label>label.prefix-text {
        display: block;
        text-align: center;
        font-size: 14px;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
    }
    .ant-form-item-label>label.prefix-text {
        opacity: 1;
        visibility: visible;
    }
    .prefix-text {
        font-size: 1rem;
    }
    .login-content {
        max-width: 90vw;
        min-height: 85vh;
        margin-bottom: 80px;
    }
    .user-input,
    .password-input {
        padding-left: 35px;
        height: 45px;
    }
    #usernameLogin.ant-input::placeholder {
        font-size: 1rem;
    }
    #passwordLogin.ant-input::placeholder {
        font-size: 1rem;
    }
}
