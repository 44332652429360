.title{
    font-weight: bold;
    font-family: terpelPosFont;
}

.acordion-resumen{
    display: flex; 
    align-items: center; 
    align-self: center; 
    text-align: center
}

.acordion-container{
    display: flex; 
    justify-content: space-between;
    font-family: terpelPosFont;
}

.item-center{
    align-self: center;
    font-family: terpelPosFont;
}

.item-align-center{
    text-align: center;
    font-family: terpelPosFont;
}

#table{
    max-width: 100%;
    overflow-x: auto;
    font-family: terpelPosFont;
    height: 100%;
}

.mount{
    font-family: terpelPosFont;
}