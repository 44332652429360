.conatiner_alertas{
    padding: 0 20px;
    margin-top: 20px;
}
.container_dashboard {
    display: flex;
    flex-wrap: wrap;
}
@media (max-width: 768px) {
    .container_dashboard {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(326px, 1fr));
        gap: 10px;
    }
}

.container__tanques_dashboard{
    position: relative;
    padding: 10px;
    display: flex; 
    width: 100%; 
    flex-direction:column;
    min-height: 100%;

}
.container__tanque_dashboard {
    display: flex;
    height: fit-content;
    align-items: center;
    overflow-x: auto;
    min-height: 100%;
    max-height: 550px;
    flex: 1;
    margin-top: 40px;
}

.conatiner_alertas .MuiAlert-icon{
    align-items: center;
}

@media (max-width:1199px) and (min-width:992px){
    
    .conatiner_alertas{
        width: 100%;
        justify-content: space-around;
    }
    .conatiner_alertas .MuiPaper-root .MuiAlert-message .MuiTypography-root{
        font-size: 0.9rem !important;
        align-items: center;
    }
    .conatiner_alertas .MuiPaper-root {
        min-width: initial !important;
    }
    
    .conatiner_alertas .cont_alert{
        width: 24%;
    }
    
}
@media (max-width:991px) and (min-width:768px){
    .conatiner_alertas .MuiPaper-root .MuiAlert-message .MuiTypography-root{
        font-size: 0.9rem !important;
        align-items: center;
    }
    .conatiner_alertas .MuiPaper-root {
        min-height: 100%;
        min-width: initial !important;
    }
    
    .conatiner_alertas .cont_alert{
        width: 24%;
    }

}

.container__ventas-filtro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 90%;
    margin-top: 5%;
    margin-bottom: 5%;
    max-height: 550px;
    min-height: 500px;
}